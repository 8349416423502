<template>
  <table-view
    :filterable=false
    :filter-form="dataForm"
    ref="tableView"
    url="/car/filling/listForPage"
    delete-url="/car/filling/deleteById"
    :batch-removable="isAuthed('car_oil_delete')"
    :addable="isAuthed('car_oil_add')"
    :editable="(row) => (row.status === 2 || row.status === 3) && isAuthed('car_oil_update')"
    :auditable="(row) => row.status === 2 && isAuthed('car_oil_approve')"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input v-model="dataForm.number" clearable placeholder="自编号"></el-input>
      <el-button size="mini" @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'car-oil',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'number', label: '自编号', minWidth: 100 },
        { id: 2, prop: 'licensePlate', label: '车牌号码', width: 120 },
        { id: 3, prop: 'createTime', label: '加油日期', width: 120 },
        { id: 5, prop: 'fillingNumber', label: '加油数量(升)', width: 120 },
        { id: 6,
          prop: 'fillingType',
          label: '加油类型',
          width: 100,
          formatter: (row, column, cellValue) => {
            switch (cellValue) {
              case 1:
                return '固定加油'
              case 2:
                return '送油'
              default:
                return '未知'
            }
          }
        },
        { id: 8,
          prop: 'status',
          label: '状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            // 0失效 1正常 2待审核 3审核不通过
            switch (cellValue) {
              case 0:
                return '失效'
              case 1:
                return '正常'
              case 2:
                return '待审核'
              case 3:
                return '审核不通过'
              default:
                return '未知'
            }
          }
        },
        { id: 9, prop: 'createUserName', label: '操作员', width: 120 }
      ],
      dataForm: {
        number: ''
      }
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  }
}
</script>
